"use client"

import dynamic from 'next/dynamic'

const DynamicChat = dynamic(
    () => import('@components/chat/views/Main'),
    { ssr: false },
)

const Chat = (props) => <DynamicChat {...props} />

export default Chat