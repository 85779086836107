import { readFromStorage, writeInStorage } from './sessionStorageAccess'
import langs from '../../src/constants/values/langs'

const LANGUAGE_KEY = 'samochody_pl_lang'
const DEFAULT_LANG = langs[0]

const setLanguage = (language) => {
    if (typeof window === 'undefined') return
    if (window[LANGUAGE_KEY] !== language) {
        writeInStorage(LANGUAGE_KEY, language)
        window[LANGUAGE_KEY] = language
    }
}

const getLanguage = () => {
    if (typeof window === 'undefined') return DEFAULT_LANG
    if (!window.LANGUAGE_KEY) {
        const language = readFromStorage(LANGUAGE_KEY)
        window[LANGUAGE_KEY] = language || DEFAULT_LANG
    }

    return window[LANGUAGE_KEY]
}

export {
    setLanguage,
    getLanguage,
}