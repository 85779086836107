import { useEffect, useState } from 'react'

const DAY = 24 * 60 * 60

const _deleteCookie = (name) => {
    document.cookie = `${name}=; path=/; max-age=0;`
}

const _getCookie = (name) => {
    const value = `; ${document.cookie}`
    const chunks = value.split(`; ${name}=`)
    if (chunks.length === 2) return chunks.pop().split(';').shift()
}

const _setCookie = (name, value, expiresInDays = 31 * 12 * DAY) => {
    if (_getCookie(name)) _deleteCookie(name)
    document.cookie = `${name}=${value}; path=/; max-age=${expiresInDays};`
}

const useCookie = (name, defaultValue) => {
    const [cookie, setCookie] = useState(
        () => (typeof window !== 'undefined' && _getCookie(name) || defaultValue)
    )

    useEffect(() => {
        if (cookie) {
            _setCookie(name, cookie)
        }
    }, [cookie, name])

    return [cookie, setCookie]
}

export default useCookie
export {
    _deleteCookie,
}