"use client"

import { useEffect } from 'react'
import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import useCookie from '@hooks/useCookie'
import BREAKPOINTS from '@constants/breakpoints'

export default function OnMobileCookie () {
    const [,setCookie] = useCookie('onMobile', '')
    const onMobile = useMediaQueryBreakpoint(BREAKPOINTS.showHamburger)

    useEffect(() => {
        if (typeof onMobile === 'boolean') setCookie(String(onMobile))
    }, [onMobile, setCookie])

    return null
}