"use client"

// import TranslationContextProvider from '@context/TranslationContext'
import ChatStateContextProvider from '@components/chat/context/ChatStateContext'

const ContextProvider = ({ children }) => (
    // <TranslationContextProvider>
        <ChatStateContextProvider>
            { children }
        </ChatStateContextProvider>
    // </TranslationContextProvider>
)

export default ContextProvider