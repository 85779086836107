"use client"

import { useEffect } from 'react'
import useSWR from 'swr'
import storage from '@utils/localStorage'
import checkLogin from '@utils/checkLogin'
import decodePayload from '@utils/jwt/decodePayload'
import checkIfExpired from '@utils/jwt/checkIfExpired'
import { renewSession, scheduleRenewSession } from '@utils/jwt/sessionUtils'

const SessionController = () => {
    if (typeof window === 'undefined') return null

    const { data: user } = useSWR('user', storage)

    useEffect(() => {
        const isLoggedIn = checkLogin(user)
        if (isLoggedIn) {
            const payload = decodePayload(user?.token)
            const expired = checkIfExpired(null, payload)

            if (expired) {
                renewSession(user)
            } else {
                scheduleRenewSession(user, payload.expires)
            }
        }
    }, [user])

    return null
}

export default SessionController