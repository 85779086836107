import { clone } from '@utils/clone/clone'
import { mutate } from 'swr'

const mutateUser = (user, assignObject = {}) => {
    if (user) {
        const newUser = mergeObjects(clone(user), assignObject)
        window.localStorage.setItem('user', JSON.stringify(newUser))
        mutate('user', newUser)
    }
}

function mergeObjects (to = {}, from = {}) {
    for (const key in from) {
        const value = from[key]
        if (typeof value === 'object') {
            to[key] = mergeObjects(to[key] || {}, value)
        } else {
            to[key] = value
        }
    }
    return to
}


export default mutateUser