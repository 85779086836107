"use client"

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

let timeoutId: any
export function CookieBotRemover ({ htmlId = 'CookiebotWidget' }: { htmlId?: string }) {
    const pathname = usePathname()
    function remove () {
        if (pathname == '/') return
        const cbw = document.getElementById(htmlId)
        if (!cbw) {
            timeoutId = setTimeout(remove, 250)
            return
        }
        cbw.style.visibility = 'hidden'
    }
    useEffect(() => {
        remove()
        return () => clearTimeout(timeoutId)
    }, [pathname])
    return null
}