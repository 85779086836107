"use client"

import dynamic from 'next/dynamic'

const DynamicWidget = dynamic(
    () => import('@components/ToTopWidget'),
    { ssr: false },
)

const ToTopWidget = (props) => <DynamicWidget {...props} />

export default ToTopWidget